<template>
  <main v-if="isOpen" class="panels-modal" :data-theme="theme">
    <h1 class="title">{{ $t("modal_painel.v2.title") }}</h1>

    <section v-if="isLoading" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </section>
    <template v-else>
      <!-- 4 or less panels -->
      <section class="panels" v-if="panels.length <= 4">
        <section
          v-for="(panel, index) in panels"
          :key="index"
          class="panel"
          @click="selectPanel"
        >
          <img
            :src="panel.logo"
            :alt="`Logo do curso ${panel.name}`"
            v-if="panel.logo"
            width="150"
            height="150"
          />
          <section
            v-else
            class="no-image"
            :class="`index-${index + 1}`"
            :style="{ background: randonBackground() }"
          >
            <img
              src="@/assets/vector.png"
              alt="imagem do busto de uma pessoa"
              width="75"
              height="75"
            />
          </section>
          <p class="panel-name">
            {{ panel.name }}
          </p>
        </section>
      </section>
      <!-- More than 4 panels  -->
      <section class="panels small-gap" v-else>
        <section
          v-for="(panel, index) in panels"
          :key="index"
          class="large-panel"
          @click="selectPanel"
        >
          <section
            class="no-image"
            :class="`index-${index + 1}`"
            :style="{ background: randonBackground() }"
          >
            <img
              src="@/assets/vector.png"
              alt="imagem do busto de uma pessoa"
              width="17"
              height="17"
            />
          </section>
          <p class="panel-name">
            {{ panel.name }}
          </p>
        </section>
      </section>
    </template>
  </main>
</template>

<script>
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
  data: () => ({
    isOpen: false,
    panels: [],
    permission: false,
    isLoading: false,
  }),
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    isStudent() {
      return (
        this.$store.getters?.currentSite?.currentMember?.role === "student"
      );
    },
  },
  methods: {
    setVisibility() {
      this.isOpen = !this.isOpen;
    },
    async getPanels() {
      await serviceMember.read("me").then((resp) => {
        Cookies.set("memberAuth", resp.member.Auth, { expires: 365 });
        this.panels = resp.member.sites;
      });
    },
    selectPanel(data) {
      this.$store.dispatch("blogSelected", data);
			this.setVisibility();
    },
    randonBackground() {
      const color1 = this.getRandomColor();
      const color2 = this.getRandomColor();
      return `linear-gradient(160deg, ${color1}, ${color2})`;
    },
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  mounted() {
    this.$root.$on("show-panel-modal-v2", async () => {
      this.isLoading = true;
      this.setVisibility();
      await this.getPanels();
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

[data-theme="light"] {
  --background: #ffffff;
  --text-color: #16171a;
  --panel-border: 1px solid rgba(22, 23, 26, 0.3);
}
[data-theme="dark"] {
  --background: #16171a;
  --text-color: #ffffff;
  --panel-border: 1px solid rgba(255, 255, 255, 0.3);
}

.panels-modal {
  position: absolute;
  z-index: 99999999999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--background);
  padding-top: 5%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: var(--text-color);
  }
  .panels {
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    max-width: 90vw;

    &.small-gap {
      gap: 30px;
    }

    .panel {
      max-width: 150px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      cursor: pointer;
      transition: ease-in-out 200ms;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border: 1px solid var(--background);
      }
      .panel-name {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: var(--text-color);
      }

      .no-image {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid var(--background);

        img {
          width: 75px;
          height: 75px;
          border: none;
        }
      }

      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        .panel-name {
          font-weight: 600;
        }
      }
    }

    .large-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 25px;
      width: 260px;
      gap: 10px;
      cursor: pointer;
      transition: ease-in-out 200ms;

      border: var(--panel-border);
      border-radius: 10px;

      .no-image {
        width: 34px;
        height: 34px;
        border-radius: 10px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 17px;
          height: 17px;
        }
      }
      .panel-name {
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: var(--text-color);
      }

      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        .panel-name {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
