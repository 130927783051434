<template>
  <div v-if="isMobile" class="bottom-navitgation">
    <div class="bottom-navitgation-item-content">
      <span
        v-for="item in menuItems"
        :key="item.path"
        @click="$router.push(`/${item.path}`)"
        :class="`bottom-navitgation-item bottom-navitgation-item-${isActive(item.path)}-${theme}`"
      >
        <img :class="{
          'bottom-icon': item.name !== 'Perfil',
          'profile-icon': item.name === 'Perfil'
        }
        " :src="item.name === 'Perfil' ? picProfile : item.icon" alt="">
        {{ item.name }}
      </span>
    </div>
  </div>
</template>

<script>
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
export default {
  props: ["isMobile"],
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    }
  },
  data() {
    return {
      menuItems: [
        { icon: 'home-mobile.svg', path: "home", name: "Início" },
        { icon: 'search-mobile.svg', path: "search", name: "Buscar" },
        { icon: 'certificates-mobile.svg', path: "certificates", name: "Certificados" },
        // { icon: 'download-mobile.svg', path: "downloads", name: "Downloads" },
        { icon: "", path: "minha-conta", name: "Conta" },
      ],
      picProfile: ''
    };
  },
  methods: {
    getPicProfile() {
      serviceMember
        .read("/meta")
        .then((resp) => {
          //console.log("meta pic profile", resp);
          this.picProfile = resp.picture;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    isActive(path) {
      const isActive = `/${path}` === this.$route.path;
      return isActive ? "active" : "unactive";
    },
    toggle() {
      this.showMenuItems = !this.showMenuItems;
    },
    handleResize() {
      if (
        (window.matchMedia("(orientation: landscape)").matches &&
          window.outerWidth < 1000) ||
        (window.matchMedia("(orientation: portrait)").matches &&
          window.outerWidth < 769 &&
          window.outerHeight < 1025)
      ) {
        this.client.width = 0;
      } else {
        this.client.width = window.outerWidth;
      }
    },
  },
  created() {
    this.getPicProfile();
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
  }
};
</script>

<style scoped>
.bottom-navitgation {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  flex-wrap: nowrap;
  height: 85px;
  background-color: var(--background2-v2);
}
.bottom-navitgation-item {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}
.bottom-navitgation-item-active-light {
  color: #16171a !important;
}
.bottom-navitgation-item-active-dark {
  color: #fff !important;
}
.bottom-navitgation-item-unactive-light {
  color: rgba(70, 70, 71, 0.5) !important;
}
.bottom-navitgation-item-unactive-dark {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bottom-navitgation-item-content {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.bottom-icon {
  height: 21px;
  margin-bottom: 15px;
}
.profile-icon {
  height: 28px;
  width: 28px;
  margin-bottom: 8px;
  border-radius: 50%;
}
</style>
