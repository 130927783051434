<template>
  <div class="navbar">
    <div v-if="!isMobile" class="navbar-item-content">
      <span
        v-for="item in menuItems"
        :key="item.path"
        @click="$router.push(`/${item.path}`)"
        :class="`navbar-item navbar-item-${isActive(item.path)}-${theme}`"
      >
        {{ item.name }}
      </span>
    </div>
    <div v-if="isMobile && $route.name !== 'Home'" class="title-mobile">
      {{ $route.name }}
    </div>
    <FillHorizontal />
    <Menu v-if="!isMobile" />
  </div>
</template>

<script>
import Menu from "../menu/index.vue";
import FillHorizontal from "../spacer/index.vue";

export default {
  props: ["isMobile"],
  components: {
    Menu,
    FillHorizontal,
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
  },
  data() {
    return {
      showMenuItems: false,
      menuItems: [
        { icon: 'home-mobile.svg', path: "home", name: "Início" },
        // { icon: 'search-mobile.svg', path: "search", name: "Buscar" },
        { icon: 'certificates-mobile.svg', path: "/home?filter=meus-cursos", name: "Meus Cursos" },
        { icon: 'certificates-mobile.svg', path: "certificates", name: "Certificados" },
        // { icon: 'download-mobile.svg', path: "downloads", name: "Downloads" },
        { icon: "", path: "favoritos", name: "Favoritos" },
      ],
    };
  },
  methods: {
    isActive(path) {
      const isActive = `/${path}` === this.$route.path;
      return isActive ? "active" : "unactive";
    },
    toggle() {
      this.showMenuItems = !this.showMenuItems;
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0.5rem 0rem;
}
.navbar-item {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 30px;
  padding-left: 0px;
  white-space: nowrap;
  cursor: pointer;
}
.navbar-item-active-light {
  color: #16171a !important;
}
.navbar-item-active-dark {
  color: #fff !important;
}
.navbar-item-unactive-light {
  color: rgba(70, 70, 71, 0.5) !important;
}
.navbar-item-unactive-dark {
  color: rgba(255, 255, 255, 0.5) !important;
}
.navbar-item-content {
  display: flex;
  flex-wrap: nowrap;
  padding: 0px;
}
.title-mobile {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--fontcolor-v2)
}
</style>
