<template>
  <div class="menus">
    <section
      class="notification"
      :class="{ 'has-notification': hasNotifications }"
    >
      <b-dropdown
        size="sm"
        variant="link-dark"
        right
        no-caret
        menu-class="custom-dropdown"
      >
        <template #button-content>
          <img
            src="/notification_icon.svg"
            alt="notification-icon-v2"
            class="notification-icon"
          />
        </template>
        <b-dropdown-header>
          <header class="header">
            <h1 class="title">
              {{ $t("notification.notifications") }}
            </h1>
            <section class="count">
              {{ notifications.length || 0 }}
            </section>
          </header>
        </b-dropdown-header>
        <b-dropdown-item
          v-for="(notification, index) in notifications.reverse()"
          :key="index"
          @click="clicked(notification)"
        >
          <section class="grid-notification">
            <section
              class="a"
              :class="{
                'no-has-picture': !notification.comment.member.picture,
                'not-viewed': !notification.viewed,
              }"
            >
              <img
                v-if="notification.comment.member.picture"
                :src="notification.comment.member.picture"
                :alt="`profile image of ${notification.comment.member.name}`"
              />
              <p v-else>
                {{ notification.comment.member.name.substr(0, 1) }}
              </p>
            </section>
            <section class="b">
              <strong>{{ notification.comment.member.name }}</strong> comentou
              no
              <strong>{{ notification.course_title }}</strong>
            </section>
            <section class="c">
              {{ notification.updated_at | moment("from", "now") }} |
              {{ notification.lesson_title }}
            </section>
            <section class="d">
              <p>
                {{ notification.comment.comment }}
              </p>
            </section>
          </section>
        </b-dropdown-item>
      </b-dropdown>
    </section>

    <section class="menu">
      <b-dropdown
        size="sm"
        variant="link"
        right
        no-caret
        menu-class="custom-dropdown"
      >
        <template #button-content>
          <img src="/account_icon_v2.svg" alt="account-icon-v2" />
        </template>
        <b-dropdown-item
          v-for="(panel, index) in painelItems"
          :key="index"
          @click="goToPanel(panel.id)"
          v-show="index < 3"
        >
          <section class="panel-items">
            <div class="icon-panel">
              <img src="/account_icon_v2.svg" :alt="`panel-${index}`" />
            </div>
            <span class="menu-panel-item-text pt-1">
              {{ panel.name }}
            </span>
            <ExplodeHorizontal />
          </section>
        </b-dropdown-item>
        <b-dropdown-item @click="$root.$emit('show-panel-modal-v2')">
          <section class="panel-items">
            <div class="icon-panel no-background">
              <img src="/panel.svg" alt="panel icon" class="menu-icons" />
            </div>
            Todos os painéis
          </section>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>

        <b-dropdown-item
          v-show="item.show"
          v-for="item in menuItems.filter((item) => item.show)"
          :key="`menu-item-${item.id}`"
          @click="item.action"
        >
          <section class="panel-items">
            <div class="icon-panel no-background">
              <img :src="item.icon" :alt="item.icon" class="menu-icons" />
            </div>
            {{ item.nome }}
          </section>
        </b-dropdown-item>
      </b-dropdown>
    </section>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import ExplodeHorizontal from "../spacer/index.vue";
// Services
import MemberService from "@/services/resources/MemberService";
import NotifyService from "@/services/resources/NotifyService";
import MetaService from "@/services/resources/MetaService";
const serviceMember = MemberService.build();
const serviceMeta = MetaService.build();
const serviceNotify = NotifyService.build();

export default {
  data() {
    return {
      client: {
        width: 0,
      },
      showMenuItems: false,
      showNotifications: false,
      painelItems: [],
      notifications: [],
    };
  },
  components: {
    ExplodeHorizontal,
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    themeIsDark() {
      return this.theme === "dark";
    },
    themeIsLight() {
      return this.theme === "light";
    },
    menuItems() {
      return [
        {
          id: 1,
          show: this.themeIsDark,
          icon: "sun.svg",
          nome: "Modo Claro",
          action: () => this.changeTheme("light"),
        },
        {
          id: 2,
          show: this.themeIsLight,
          icon: "sun.svg",
          nome: "Modo Escuro",
          action: () => this.changeTheme("dark"),
        },
        {
          id: 3,
          show: true,
          icon: "account.svg",
          path: "minha-conta",
          nome: "Conta",
          action: () => this.changeRoute("/minha-conta"),
        },
        {
          id: 4,
          show: true,
          icon: "help.svg",
          nome: "Central de Ajuda",
          action: () => this.changeRoute("/ajuda"),
        },
        {
          id: 5,
          show: true,
          icon: "logout.svg",
          nome: "Finalizar Sessão",
          action: () => this.logout(),
        },
      ];
    },
    hasNotifications() {
      return !!this.notifications.length;
    },
  },
  methods: {
    changeRoute(path) {
      this.$router.push(path);
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changeTheme(theme) {
      let data = {
        id: "theme_fixed_painel_color",
        value: theme,
      };

      document.documentElement.setAttribute("data-theme", theme);
      this.$root.$emit("loadTheme");
      this.$root.$emit("loadsettheme");

      serviceMeta.postID(data).then(() => {
        Cookies.set("themeClub", theme, { expires: 365 });
        this.$store.commit("ClubTheme", theme);
      });
    },
    getPanels() {
      serviceMember.read("me").then((resp) => {
        Cookies.set("memberAuth", resp.member.Auth, { expires: 365 });
        this.painelItems = resp.member.sites;
      });
    },
    toggle(from = "menu") {
      if (from === "menu") this.showMenuItems = !this.showMenuItems;
      else this.showNotifications = !this.showNotifications;
    },
    async getNotifications() {
      await serviceNotify
        .search()
        .then((resp) => {
          this.notifications = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async clicked(notification) {
      await serviceNotify
        .postID({ id: notification.id, viewed: true })
        .then(() => {
          this.getNotifications();
        })
        .finally(() => {
          this.$router.push(
            `/curso/${notification.course_id}/modulo/${notification.module_id}/aula/${notification.lesson_id}?c_id=${notification.lesson_comment_id}`
          );
        });
    },
  },
  created() {
    this.getPanels();
    this.getNotifications();
    window.addEventListener(
      "resize",
      () => (this.client.width = window.outerWidth)
    );
    this.handleResize();
  },
};
</script>

<style lang="scss" scoped>
.menus {
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
  gap: 15px;
}
.icon-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  min-height: 35px;
  border-radius: 10px;

  &:not(&.no-background) {
    background-color: #00483b; /* Cor de fundo do ícone */
  }
}
.menu {
  /* Propriedades de estilo básicas do ícone */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: #00483b; /* Cor de fundo do ícone */
  border-radius: 10px; /* Bordas arredondadas para criar um ícone circular */
  cursor: pointer;
  z-index: 10;
  /* Transições para suavizar a animação */
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.menu:hover {
  /* Propriedades de estilo ao passar o cursor sobre o ícone */
  box-shadow: 0 0 10px #00483b; /* Sombra amarela para simular o brilho */

  /* Animação pulsante para adicionar efeito de brilho */
  /* animation-name: transform;
  animation-duration: 3s;
  animation-fill-mode: ease-in-out; */

  /* Outros estilos se necessário */
}

.menu-items-panels-container {
  position: absolute;
  right: 12px;
  border-radius: 20px;
  margin-top: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-width: 257px;
  z-index: 20;
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
}

.menu-panel-item-text {
  white-space: nowrap;
}
/* .menu-item {
  min-width: 257px;
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
} */
.panel-item {
  min-width: 257px;
  display: flex;
  cursor: pointer;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
}

.menu-item {
  display: flex;
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 28px;
  cursor: pointer;
}

.divider {
  height: 1px;
  display: flex;
  width: 80%;
  margin-top: 6px;
  margin-bottom: 21px;
  margin-right: 10%;
  margin-left: 10%;
  border: 1px solid var(--fontcolor2-v2);
}

.notification {
  @extend .menu;
  background-color: #00483bb2;
  position: relative;

  &:hover {
    box-shadow: 0 0 10px #00483bb2;
  }

  &.has-notification::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #01caa6;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    z-index: 999999999999999999999;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  .count {
    text-align: center;
    color: #01caa6;
    background: rgba(1, 202, 166, 0.1);
    border-radius: 2px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
  }
}

.panel-items {
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-icons {
  width: 18px;
  height: 18px;
}

.grid-notification {
  display: grid;
  grid-gap: 5px;
  .a {
    grid-area: a;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    &.no-has-picture {
      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
      }
      background: var(--maincolor);
    }

    &.not-viewed::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      background: #01caa6;
      border-radius: 50%;
      z-index: 999999999999999999999;
    }
  }
  .b {
    grid-area: b;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .c {
    grid-area: c;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9a9a9a;
  }
  .d {
    grid-area: d;
    max-width: 355px;
    background: var(--closes-bg);
    border-radius: 10px;
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    p {
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  grid-template-areas:
    "a b b b"
    "a c c c"
    "a d d d";
}
</style>
