<template>
  <main class="site-template-v2">
    <slot v-if="highlight"></slot>
    <b-container v-else fluid>
      <section class="content">
        <Navbar />
        <slot></slot>
      </section>
    </b-container>
    <BottomNavigation :isMobile="isMobile" />
    <panels-modal-v2 />
    <buy-course-modal />
  </main>
</template>

<script>
// import { EventBus } from "@/main.js";
// Components
import Navbar from "@/components/V2/navbar/index.vue";
import BottomNavigation from "@/components/V2/bottom-navigation/index.vue";
import PanelsModalV2 from "@/components/V2/modals/PanelsModal";
import BuyCourseModal from "../components/V2/modals/BuyCourseModal.vue";
import MetaService from "@/services/resources/MetaService";
import Cookies from "js-cookie";

const serviceMeta = MetaService.build();

export default {
  name: "SiteTemplateV2",
  props: ["highlight"],
  components: {
    Navbar,
    BottomNavigation,
    PanelsModalV2,
    BuyCourseModal,
  },
  data() {
    return {
      luzApagada: false,
      client: {
        width: 0,
      },
    };
  },
  mounted() {
    this.getThemeFixed();
    this.getClubVersion();

    document.body.style.backgroundImage = "";

    this.$root.$on("luzOn", () => {
      this.luzApagada = true;
    });
    this.$root.$on("luzOff", () => {
      this.luzApagada = false;
    });
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    insertTheme() {
      const d = document;
      let theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light");
        document.getElementById("slider").checked = false;
      } else {
        d.documentElement.setAttribute("data-theme", theme);
        if (theme === "light") {
          document.getElementById("slider").checked = false;
        } else {
          document.getElementById("slider").checked = true;
        }
      }
      this.$root.$emit("loadTheme");
      this.$root.$emit("loadsettheme");
    },
    getThemeFixed() {
      serviceMeta
        .search("keys[]=theme_fixed_painel")
        .then((resp) => {
          if (resp.theme_fixed_painel === "on") {
            this.getThemeFixedColor();
          } else {
            this.insertTheme();
          }
        })
        .catch((err) => console.error(err));
    },
    getThemeFixedColor() {
      serviceMeta
        .search("keys[]=theme_fixed_painel_color")
        .then((resp) => {
          let theme = "";
          if (resp.theme_fixed_painel_color === "light") {
            Cookies.set("themeClub", "light", { expires: 365 });
            theme = "light";
          } else {
            Cookies.set("themeClub", "dark", { expires: 365 });
            theme = "dark";
          }
          document.documentElement.setAttribute("data-theme", theme);
          this.$root.$emit("loadTheme");
          this.$root.$emit("loadsettheme");
        })
        .catch((err) => console.error(err));
    },
    getClubVersion() {
      serviceMeta
        .search("keys[]=club_version")
        .then((resp) => {
          if (resp.club_version === "v2") {
            Cookies.set("clubVersion", "v2", { expires: 365 });
          } else {
            Cookies.set("clubVersion", "v1", { expires: 365 });
          }
        })
        .catch((err) => console.error(err));
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
  },
  created() {
    this.detectOs();
    this.client.width = window.innerWidth;
    window.addEventListener(
      "resize",
      () => (this.client.width = window.innerWidth)
    );
  },
  updated() {
    this.client.width = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener(
      "resize",
      () => (this.client.width = window.innerWidth)
    );
  },
};
</script>

<style lang="scss">
.site-template-v2 {
  *,
  & {
    box-sizing: border-box;
  }

  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
</style>
